<div>
    <div class="fs-14 fw-600 lh-1 mb-2" *ngIf="label">{{ label }}<span class="text-danger ms-1" *ngIf="required">*</span></div>
    <div class="input-container">
        <input
            #inputBox
            pInputText
            [formControl]="control"
            [ngClass]="{
                'p-inputtext-sm': size === 'sm',
                'p-inputtext-lg': size === 'lg',
                'w-100': block,
                'ng-invalid ng-dirty': control.touched && control.invalid
            }"
            autocomplete="off"
            [type]="type"
            [placeholder]="placeholder || ''"
            (keydown.enter)="enterEvent.emit($event)"
            (blur)="blurEvent.emit($event)"
        />
        <span *ngIf="clearIcon === true" [ngClass]="this.control.value !== '' ? 'pi pi-times pointer clear-icon' : ''" (mousedown)="clearFilter($event)"></span>
    </div>
    <ng-container>
        <app-form-error *ngIf="control.touched" [errors]="control.errors" />
    </ng-container>
</div>
